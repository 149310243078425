<template>
    <div class="xw_page_container">
        <div class="member_header">
            <div class="member_title_name " v-if="$store.state.windowWidth > 768">
                {{ $t('常見問題') }}
            </div>

            <div class="bg_header" v-else>
                <div class="header_content">
                    <button @click="goBack" type="button" class="btn_back"></button>
                    <span>{{ $t('常見問題') }}</span>
                </div>
            </div>
        </div>

        <div class="member_content">
            <div class="main_content">

            </div>
        </div>
        <xwTabbar v-if="$store.state.windowWidth <= 768" />

    </div>
</template>

<script>
import xwTabbar from "@/components/xwTabbar.vue";
export default {
    components: {
        xwTabbar,
    },
    data() {
        return {
        }
    },
    mounted() {

    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
    }
}
</script>

<style lang="less" scoped>
.xw_page_container {
    width: 100vw;
    min-height: var(--vh);
    background-color: #ebebeb;
}

.member_header {
    position: relative;
    width: 100%;
    height: 60px;
    background-color: #3a9b7c;

    @media (max-width:768px) {
        position: fixed;
        top: 0;
        left: 0;
        height: 40px;
        background-color: #3f9eff;
        z-index: 10;
    }
}

.member_title_name {
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 22px;
    font-weight: bold;
    margin-left: 34px;
}

.search_item {
    margin-left: 0;
    height: 0;
    position: absolute;
    top: 24px;
    left: 179px;
    width: max-content;
}

.member_tab_list {
    list-style: none;
    display: flex;
    align-items: center;
    user-select: none;
}

.member_tab_item {
    min-width: auto;
    padding: 0 17px;
    text-align: center;
    height: 36px;
    line-height: 36px;
    width: max-content;
    font-size: 13px;
    font-weight: normal;
    color: #fff;
    border-radius: 5px 5px 0 0;
    cursor: pointer;
}

.member_tab_item.active {
    background-color: #ebebeb !important;
    color: #3a9b7c;
}

.bg_header {
    position: fixed;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 99;
}

.header_content{
    position: relative;
    color: #fff;
    width: 100%;
    height: 100%;
    line-height: 40px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    font-weight: bold;
}

.btn_back {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    cursor: pointer;
    width: 40px;
    display: block;
    background: url('../../assets/images/xw/btn_back.svg') no-repeat center;
    background-size: auto 40%;
}

.member_content {
    width: fit-content;
    margin: 0 auto;
    padding: 15px;

    @media (max-width:768px) {
        padding: 15px;
    }
}

.main_content{
    width: 92%;
    max-width: 1000px;
    min-height: 300px;
    box-sizing: border-box;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 3px;
    padding: 5%;
    font-size: .8em;
    line-height: 1.7em;
    word-break: break-all;
    text-align: justify;
}
</style>